import styled, { css } from "styled-components"
import { FaBars, FaShippingFast, FaRegCalendarAlt, FaKeyboard, FaCheck, FaMotorcycle } from "react-icons/fa"
import {
  AiOutlineUser,
  AiOutlinePlusCircle,
  AiOutlineMinusCircle,
  AiOutlineClose,
  AiOutlineArrowRight,
  AiOutlineSolution,
} from "react-icons/ai"
import { IoChevronBackOutline, IoPricetags, IoStorefront } from "react-icons/io5"
import { RiCloseLine, RiArrowDownSFill, RiArrowUpSFill, RiArrowDropDownLine, RiInboxArchiveFill } from "react-icons/ri"
import { BsCheck, BsSearch, BsInfoCircle, BsBookmarkCheck } from "react-icons/bs"
import { FiFileText, FiPackage, FiTruck, FiClock, FiPrinter } from "react-icons/fi"
import { IoIosArrowForward, IoMdArrowRoundBack, IoIosArrowDown } from "react-icons/io"
import { ImSpinner2, ImQrcode, ImUpload3 } from "react-icons/im"
import { FiSlash } from "react-icons/fi"
import { BiErrorAlt, BiBarcodeReader, BiUpvote, BiFile, BiTrash } from "react-icons/bi"
import { GiBoxUnpacking, GiSave, GiArchiveRegister } from "react-icons/gi"
import { CgCloseR } from "react-icons/cg"
import { VariantSpinnerType } from "types/VariantSpinnerType"
import { spin } from "theme/base/Animations"
import { FcLockPortrait } from "react-icons/fc"
import { VscLock } from "react-icons/vsc"
import { GrDocument, GrDocumentPdf, GrAttachment } from "react-icons/gr"
import { HiSortAscending, HiSortDescending } from "react-icons/hi"
import { GoFileMedia } from "react-icons/go"
import { TbPackgeImport } from "react-icons/tb"
import { IoSpeedometerSharp } from "react-icons/io5";

const cssIconsCircle = css<{ disabled?: boolean }>`
  color: ${({ theme }) => theme.palette.grey60};
  font-size: 2.143rem;
  cursor: pointer;
  &:active {
    transform: scale(0.9);
  }

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.palette.grey20};
      pointer-events: none;
      cursor: none;
    `}
`
export const IconBoxCheck = styled(FaCheck)``

export const IconBarsMenu = styled(FaBars)`
  color: ${({ theme }) => theme.palette.grey20};
`
export const IconUser = styled(AiOutlineUser)`
  color: ${({ theme }) => theme.palette.grey20};
`
export const IconLock = styled(VscLock)``
export const IconArrowLeft = styled(IoChevronBackOutline)``

export const IconArrowRigth = styled(IoIosArrowForward)``

export const IconArrowOutlineRigth = styled(AiOutlineArrowRight)``

export const IconAiOutlineSolution = styled(AiOutlineSolution)``

export const IconIoSpeedometerSharp = styled(IoSpeedometerSharp)``

export const IconFaMotorcycle = styled(FaMotorcycle)``

export const IconClose = styled(RiCloseLine)``

export const IconArrowDown = styled(RiArrowDownSFill)``

export const IconArrowDownMain = styled(IoIosArrowDown)``

export const IconIoStorefront = styled(IoStorefront)``

export const IconArrowDropDown = styled(RiArrowDropDownLine)``

export const InboxArchiveFill = styled(RiInboxArchiveFill)``

export const IconArrowUp = styled(RiArrowUpSFill)``

export const IconCheck = styled(BsCheck)``

export const IconFileText = styled(FiFileText)``

export const IconPackage = styled(FiPackage)``

export const IconTruck = styled(FiTruck)``

export const IconClock = styled(FiClock)``

export const IconSearch = styled(BsSearch)``
export const IconTruckDelivery = styled(FaShippingFast)``

export const IconCirclePlus = styled(AiOutlinePlusCircle)`
  ${cssIconsCircle}
`

export const IconCircleMinus = styled(AiOutlineMinusCircle)`
  ${cssIconsCircle}
`

export const IconBarcode = styled(BiBarcodeReader)`
  font-size: 2rem;
`
export const IconUpLoad = styled(BiUpvote)``

export const IconFile = styled(BiFile)``

export const IconTrash = styled(BiTrash)``

export const IconCancel = styled(FiSlash)``

export const IconReject = styled(AiOutlineClose)``

export const IconError = styled(BiErrorAlt)``

export const IconInfo = styled(BsInfoCircle)``

export const IconPriceTag = styled(IoPricetags)``

export const IconCalendar = styled(FaRegCalendarAlt)``

export const IconSpinner = styled(ImSpinner2)<{ variant: VariantSpinnerType }>`
  animation: ${spin} infinite 2s linear;
  color: ${({ theme }) => theme.palette.secondary};

  ${({ variant }) => {
    switch (variant) {
      case "small":
        return css`
          font-size: 1rem;
        `
      case "medium":
        return css`
          font-size: 1.5rem;
        `

      case "big":
        return css`
          font-size: 2.5rem;
        `
    }
  }}
`
export const IconBoxUnpacking = styled(GiBoxUnpacking)``

export const IconBoxArchiveRegister = styled(GiArchiveRegister)``

export const IconSquareClose = styled(CgCloseR)``

export const IconKeyBoard = styled(FaKeyboard)``

export const IconBackArow = styled(IoMdArrowRoundBack)``

export const IconLocker = styled(FcLockPortrait)``

export const IconDocument = styled(GrDocument)``

export const IconDocumentPDF = styled(GrDocumentPdf)``

export const IconQrCode = styled(ImQrcode)``

export const IconPrint = styled(FiPrinter)``

export const IconSortAsc = styled(HiSortAscending)``

export const IconSortDesc = styled(HiSortDescending)``

export const IconUpload = styled(ImUpload3)``

export const IconAttachment = styled(GrAttachment)``

export const IconSave = styled(GiSave)``

export const IconFileImage = styled(GoFileMedia)``

export const IconPackgeImport = styled(TbPackgeImport)``

export const IconBsBookmarkCheck = styled(BsBookmarkCheck)``
